<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="tokens.menu.tokenStock"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
        :footer-props="{
          ...rowsPerPageItemsOptions,
          itemsPerPageText: $t('common.table.itemsPerPageText'),
          pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
        }"
        :headers="headers"
        :items="tokens"
        :loading="loading"
        :no-data-text="$t('common.ui.not_data_found')"
        :no-results-text="$t('common.ui.not_search_found')"
        :search="search"
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar color="white" flat>
          <v-row class="d-flex justify-space-between align-baseline pb-10">
            <v-col class="pl-0" cols="6" lg="4" md="4" sm="6" xl="4">
              <v-text-field
                  v-model="search"
                  :clearable="true"
                  :label="$t('common.titles.filter')"
                  append-icon="mdi-magnify"
                  color="primary"
                  dense
                  filled
                  hide-details
                  onkeypress="return (event.charCode != 34)"
                  rounded
                  single-line
                  v-on:keyup.86="replaceSearch"
                  v-on:keyup.ctrl.86="replaceSearch"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="pl-0 justify-end d-flex" cols="6" lg="4" md="4" sm="6" xl="4">
              <LoginMetamask view="INVENTORY"/>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.product="{ item }">
        <div class="d-flex justify-start align-center">
          <div class="d-flex justify-start align-center align-content-center">
            <p class="ma-0 px-2 py-1 token-name ">
              {{ item.product | truncate(20) }}
            </p>
            <div>
              <p class="ma-0 px-2 py-1 token-address__default">
                {{ item.symbol }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.contractAddress="{ item }">
        <a :href="`${polygonNet}/address/${item.contractAddress}`" target="_blank">
          <p class="d-flex justify-space-between align-center align-content-center ma-0 px-2 py-1 token-active" v-if="item.status === 'RUNNING'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="success" small right>
              mdi-link
            </v-icon>
          </p>
          <p class="d-flex justify-space-between align-center align-content-center ma-0 px-2 py-1 token-inactive" v-if="item.status === 'STOPPED'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="error" small right>
              mdi-link
            </v-icon>
          </p>
          <p class="d-flex justify-space-between align-center align-content-center ma-0 px-2 py-1 token-pending" v-if="item.status === 'PENDING'">
            {{ item.contractAddress | truncateAddress(10) }}
            <v-icon color="warning" small right>
              mdi-link
            </v-icon>
          </p>
        </a>
      </template>
      <template v-slot:item.action="{ item }">
        <span v-if="(item.realStock > 0)">
          <!-- DialogEmitToken -->
          <TokenShowComponent :token="item"/>

          <!-- DialogEmitToken -->
          <TokenEmitComponent :token="item" v-if="item.status === 'RUNNING'"/>

          <!-- DialogBurnToken -->
          <TokenBurnComponent :token="item" v-if="item.status === 'RUNNING'"/>
        </span>
      </template>
    </v-data-table>
    <TimeSyncedComponent section='STOCK' :box-styles='boxStyles' :text-styles='textStyles'/>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n"
import Web3  from "web3";
import { mapActions, mapGetters } from 'vuex'
import factoryJSON from "../../../../build/contracts/Factory.json"
import ERC1155ModelJSON from "../../../../build/contracts/ERC1155Model.json"
import LoginMetamask from "@/module/tokens/commons/LoginMetamask";
import ContractHistoryComponent from "@/module/tokens/contract/components/ContractHistoryComponent";
import TokenShowComponent from "@/module/tokens/stock/components/TokenShowComponent";
import TokenEmitComponent from "@/module/tokens/stock/components/TokenEmitComponent";
import TokenBurnComponent from "@/module/tokens/stock/components/TokenBurnComponent";
import TimeSyncedComponent from '@/module/tokens/commons/TimeSyncedComponent'

export default {
  name: 'IndexView',
  components: {
    TimeSyncedComponent,
    ContractHistoryComponent, TokenShowComponent, TokenEmitComponent, TokenBurnComponent, LoginMetamask},
  data: () => ({
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [5, 10, 20, 50, 100, 200],
    },
    web3js: new Web3(window.ethereum),
    search: '',
    loading: false,
    polygonNet: process.env.VUE_APP_POLYGON_SCAN,
    boxStyles: 'background: #005b4a; padding: 5px 20px; border-radius: 7px 7px 0 0;',
    textStyles: 'color: white; font-size: 12px;'
  }),

  computed: {
    ...mapGetters({
      web3: 'web3/GET_WEB3',
      tokens: 'web3/GET_TOKEN_LIST',
      getTotalMinted: 'web3/GET_TOTAL_MINTED',
      factory: 'web3/GET_FACTORY'
    }),
    headers() {
      return [
        {
          text: i18n.t('inventory.titles.product') + '/' + i18n.t('inventory.titles.symbol'), value: 'product', val: 'product'
        },
        {
          text: i18n.t('inventory.titles.contract'), value: "contractAddress", val: "contractAddress", width: 220
        },
        {
          text: i18n.t('inventory.titles.available'), value: 'virtualStock', val: 'virtualStock'
        },
        {
          text: i18n.t('inventory.titles.tokens'), value: 'tokenized', val: 'tokenized'
        },
        {
          text: i18n.t('inventory.titles.total'), value: 'realStock' , val: 'realStock'
        },
        {
          text: i18n.t('common.fields.action'), value: 'action', width: 150, sortable: false, align: 'start',
        },
      ]
    }
  },

  async created() {
    await this.initialize();
  },

  mounted() {
    window.ethereum.on('accountsChanged', () => {
      this.$store.commit('web3/DEFAULT_TOKEN_LIST')
      this.initialize()
    })

    this.$root.$on('initializeInventoryData', async () => {
      await this.initialize()
    })

    this.$root.$on('closeInventoryData', async () => {
      this.search = null
    })
  },

  methods: {
    ...mapActions({
      ifUpdateAddress: 'web3/IF_UPDATE_ADDRESS',
      startListeningWalletChanges: 'web3/START_LISTENING_WALLET_CHANGES',
      fetchStock: 'web3/FETCH_STOCK',
      loadFactoryData: 'web3/LOAD_FACTORY_DATA'
    }),
    async initialize() {
      this.loading = true

      if (!window.ethereum || !window.userAddress) {
        console.log("Não foi detectado a extenção ou conexão com a Metamask.");
      }

      // window.userAddress = window.localStorage.getItem('eth_account');
      // this.$store.commit('web3/REGISTER_WEB3_INSTANCE', window.userAddress)

      await this.web3js.eth.getAccounts()
          .then(accounts => {
            window.userAddress = accounts[0]
            this.$store.commit('web3/REGISTER_WEB3_INSTANCE', accounts[0])
            localStorage.setItem('eth_account', accounts[0])
            this.ifUpdateAddress().then(console.log);
          });

      // this.startListeningWalletChanges()

      await this.loadFactoryData({factoryJSON, ERC1155ModelJSON})

      await this.fetchStock().finally(async () => {
        this.loading = false
      })
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
    },
    replaceSearch(event) {
      this.search = event.currentTarget.value.toString().replaceAll('"', '')
    },
  },

  destroyed() {
    this.$root.$off('initializeInventoryData')
    this.$root.$off('closeInventoryData')
  }
}
</script>

<style>
.zebra-bg tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.token-name {
  background: rgba(0, 0, 0, .07);
  color: #1a1a1a;
  border-radius: .3em 0 0 .3em;
  font-size: 12px;
  font-weight: 500;
}

.token-address__default {
  border-radius: 0 .3em .3em 0;
  font-size: 12px;
  font-weight: 500;
  background: #f3f3f3;
  color: #1a1a1a;
}

a {
  text-decoration: none !important;
}
</style>
